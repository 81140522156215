import { Injectable } from '@angular/core';
import { I18nLanguage } from '../models/i18n.models';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor() {}

  getCurrentLanguage(): I18nLanguage | null {
    const currentLanguage = localStorage.getItem('currentLanguage');
    return currentLanguage ? JSON.parse(currentLanguage) : null;
  }

  saveCurrentLanguage(language: I18nLanguage): void {
    localStorage.setItem('currentLanguage', JSON.stringify(language));
  }

  getFormattedDateTime({ date }: { date: Date }) {
    const formatDate = (date: Date): string => {
      const pad = (number: number): string =>
        number < 10 ? `0${number}` : `${number}`;

      const year = date.getFullYear();
      const month = pad(date.getMonth() + 1);
      const day = pad(date.getDate());
      const hours = pad(date.getHours());
      const minutes = pad(date.getMinutes());
      const seconds = pad(date.getSeconds());

      return `${year}-${month}-${day}-${hours}-${minutes}-${seconds}`;
    };

    return formatDate(date);
  }
}
